export const authLinks = [
  {
    name:'Join',
    path: `${process.env.TOTALLY_LESSONS_URL}/sign-up`
  },
  {
    name:'Sign In',
    path: `${process.env.TOTALLY_LESSONS_URL}/sign-in`
  },
];

export const specialsSubLinks = [
  {
    name:'all',
    path: '/specials?tags=8:7',
  },
  {
    name:'lessons',
    path: '/lessons',
  },
  {
    name:'packs',
    path: '/packages',
  },
  {
    name:'artists',
    path: '/bands',
  },
]

export const mainLinks = [
  {
    name:'home',
    path: '/',
    partiallyActive: false
  },
  {
    name:'content',
    path: '/specials?tags=8:7',
    subLinks: specialsSubLinks,
    partiallyActive: true
  },
  {
    name:'membership',
    path: '/pricing',
    partiallyActive: true
  },
];

export const shoppingCartLink = {
  name: 'cart',
  path: '/shopping-cart'
};

export const giftsLink = {
  name: 'gifts',
  path: '/gifts'
};

export const externalLink = [
  {
    name: 'Forum',
    path: process.env.FORUM_URL
  },
  {
    name: 'Blog',
    path: process.env.BLOG_URL
  },
  {
    name: 'IGC',
    path: process.env.IGC_URL
  },
  /*
  {
    name: 'TG Old',
    path: process.env.TG_OLD_URL
  },
  */
  {
    name: 'Help',
    path: process.env.HELP_URL
  }
];