import store from 'store2';
import { ShoppingCartItem } from '../Models/ShoppingCartItem';
import { ShoppingCartList } from '../Models/ShoppingCartList';
import axiosService from './axios.service';
import { handleInternalServerError } from '../helpers/error-handler.helper';
import hasWindow from '../constants/has-window.const';

class ShoppingCartService {
  storageKey = 'cartToken';
  apiUrl = `${process.env.API_URL}/carts`;

  count = 0;
  events = {
    updateCount: 'updateCountEvent',
    updateToken: `${this.storageKey}Changed`
  }

  updateCountEvent = hasWindow ? new Event(this.events.updateCount, { bubbles: true, cancelable: true }) : null;
  updateTokenEvent = hasWindow ? new Event(this.events.updateToken, { bubbles: true, cancelable: true }) : null;

  hasToken() {
    return !!(this.getCartToken());
  }

  getCartItems() {
    if (this.hasToken()) {
      return axiosService.get(`${this.apiUrl}/${this.getCartToken()}`)
        .then((res) => {
          return new ShoppingCartList({
            products: (res.data.data.products || []).map(item => new ShoppingCartItem(item)),
            totalPrice: res.data.data.totalPrice,
            totalCount: res.data.meta.totalCount
          });
        });
    }

    return Promise.resolve(new ShoppingCartList({}));
  }

  async createCart() {
    return await axiosService.post(`${this.apiUrl}`, {})
      .then((res) => {
        return res.data.data.token;
      });
  }

  async addLessonToCart(productId) {
    if (this.hasToken()) {
      return await axiosService.post(`${this.apiUrl}/${this.getCartToken()}/lesson`, { productId })
        .then((res) => {
          const cartItemData = res.data.data;
          return new ShoppingCartItem(cartItemData);
        });
    }
  }

  async addPackageToCart(productId) {
    if (this.hasToken()) {
      return await axiosService.post(`${this.apiUrl}/${this.getCartToken()}/package`, { productId })
          .then((res) => {
            const cartItemData = res.data.data;
            return new ShoppingCartItem(cartItemData);
          });
    }
  }

  deleteOne(id) {
    if (this.hasToken() && id) {
      return axiosService.delete(`${this.apiUrl}/${this.getCartToken()}/${id}`);
    }
  }

  deleteAll() {
    if (this.hasToken()) {
      return axiosService.delete(`${this.apiUrl}/${this.getCartToken()}`);
    }
  }

  updateCount() {
    this.getCount()
      .then((value) => {
        this.count = value;
        if (hasWindow) {
          document.dispatchEvent(this.updateCountEvent);
        }
      })
      .catch(err => (handleInternalServerError(err)))
  }

  getCount() {
    if (this.hasToken()) {
      return axiosService.get(`${this.apiUrl}/${this.getCartToken()}/count`)
        .then((res) => res.data.data.count);
    }
    return Promise.resolve(0);
  }

  getCartToken() {
    return store.get(this.storageKey);
  }

  triggerUpdateTokenEvent() {
    if (this.updateTokenEvent) {
      document.dispatchEvent(this.updateTokenEvent);
    }
  }

  setCartToken(token) {
    store.set(this.storageKey, token);
    this.triggerUpdateTokenEvent();
  }

  removeCartToken() {
    store.remove(this.storageKey);
    this.triggerUpdateTokenEvent();
  }
}

const shoppingCartService = new ShoppingCartService();

export default shoppingCartService;
