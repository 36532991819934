export class ShoppingCartList {
  products = [];
  totalPrice = 0;
  totalCount = 0;

  constructor(props) {
    this.products = props.products || this.products;
    this.totalPrice = props.totalPrice || this.totalPrice;
    this.totalCount = props.totalCount || this.totalCount;
  }
}
