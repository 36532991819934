import { store } from 'react-notifications-component';

export default class NotificationService {
  static success = success;
  static info = info;
  static warning = warning;
  static error = error;
}

function success(message = '') {
  notify(message,'success');
}

function info(message) {
  notify(message,'info');
}

function warning(message) {
  notify(message,'warning');
}

function error(message) {
  notify(message,'danger');
}

export function notify(message, type) {
  store.addNotification({
    type,
    message,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: false,
      showIcon: true
    }
  });
}
