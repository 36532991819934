import React from 'react';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import TGLogo from '../../../../img/oldlogo-white.png';
import './navbar.scss';
import NavListLarge from './components/NavListLarge';
import NavListSmall from './components/NavListSmall';
import { mediumBreakpoint } from '../../../../constants/breakpoints.const';
import ShoppingCartIconLink from './components/ShoppingCartIconLink';
import { giftsLink, shoppingCartLink } from '../../../../constants/nav-links.const';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navMobileActiveClass: '',
      isMediumScreen: false,
    };
  }

  componentDidMount() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth = () => {
    this.setState({isMediumScreen: window.innerWidth < mediumBreakpoint }, () => {
      if (!this.state.isMediumScreen) {
        clearAllBodyScrollLocks();
      } else {
        this.setState({ active: false });
        this.setState({ navMobileActiveClass: '' });
      }
    });
  };

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        if (this.state.active) {
          this.setState({ navMobileActiveClass: 'is-active' });
          const targetElement = document.querySelector('#navMobile');
          disableBodyScroll(targetElement);
        } else {
          this.setState({ navMobileActiveClass: '' });
          clearAllBodyScrollLocks();
        }
      }
    );
  };

  render() {
    const headerButtons = (
      <>
        <div className="nav-icons ml-auto">
          <Link  className={`nav-icon-link`} to={giftsLink.path} activeClassName="active">
            <span className="uil uil-gift"/>
          </Link>
          <ShoppingCartIconLink/>
        </div>
        <button className={`btn-toggle ${this.state.navMobileActiveClass}`} onClick={() => this.toggleHamburger()}>
          <span><span className="d-none">Toggle menu</span></span>
        </button>
      </>
    );

    return (
      <nav className="nav fixed-top d-block" aria-label="main-navigation">
        <div className="container bg-primary h-100 z-index-101">
          <div className="row h-100">
            <div className="col d-flex align-items-center justify-content-between">
              <div className="brand-logo mr-2">
                <Link to="/" onClick={clearAllBodyScrollLocks} title="Totally guitars">
                  <img src={TGLogo} alt="Totally Guitars" />
                </Link>
              </div>
              { !this.state.isMediumScreen ? <NavListLarge/> : null }
              { this.state.isMediumScreen ? headerButtons : null }
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.isMediumScreen && this.state.active}
          timeout={400}
          classNames="nav-mobile"
          unmountOnExit
        >
          <NavListSmall navMobileActiveClass={this.state.navMobileActiveClass} />
        </CSSTransition>
      </nav>
    )
  }
};

export default Navbar;
