import { graphql, useStaticQuery } from 'gatsby'
// (filter: { frontmatter: { templateKey: { eq: "footer" } } })
const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
          meta: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "meta"}}}) {
              edges {
                  node {
                      id
                      frontmatter {
                          siteMetadata {
                              description
                              siteTitle
                          }
                          openGraphTags {
                              ogdescription
                              ogsitename
                              ogtitle
                              ogurl
                              ogimage {
                                  childImageSharp {
                                      fluid(maxWidth: 500) {
                                          ...GatsbyImageSharpFluid
                                      }
                                  }
                              }
                          }
                          twitterTags {
                              twitterDescription
                              twitterImage{
                                  childImageSharp {
                                      fluid(maxWidth: 500) {
                                          ...GatsbyImageSharpFluid
                                      }
                                  }
                              }
                              twitterImageAlt
                              twitterTitle
                          }
                      }
                  }
              }
          }
          social: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "social"}}}) {
              edges {
                  node {
                      id
                      frontmatter {
                        facebook {
                          url
                          icon {
                            childImageSharp {
                              fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                            extension
                            publicURL
                          }
                        }
                        pinterest {
                          url
                          icon {
                            childImageSharp {
                              fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                            extension
                            publicURL
                          }
                        }
                        twitter {
                          url
                          icon {
                            childImageSharp {
                              fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                            extension
                            publicURL
                          }
                        }
                        youtube {
                          url
                          icon {
                            childImageSharp {
                              fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                            extension
                            publicURL
                          }
                        }
                      }
                  }
              }
          }
      }
    `
  )
  return data
}

export default useSiteMetadata
