export class ShoppingCartItem {
  id = null;
  title = '';
  thumbnail = '';
  price = 0;
  type = '';

  constructor(props) {
    this.id = props.id || this.id;
    this.title = props.title || this.title;
    this.thumbnail = props.thumbnail || this.thumbnail;
    this.price = props.price || this.price;
    this.type = props.type || this.type;
  }
}
