export function hasActiveSubLink(subLinks) {
  const hasWindow = typeof window !== 'undefined';
  if (!hasWindow || !Array.isArray(subLinks) || subLinks.length === 0 ) {
    return false;
  }

  const href = window.location.href;

  return subLinks.some((subLink)=> {
    if (!subLink) {
      return false;
    }

    return href.endsWith(subLink.path);
  })
}

