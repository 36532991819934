import React, { useEffect, useState } from 'react';
import { shoppingCartLink } from '../../../../../constants/nav-links.const';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import shoppingCartService from '../../../../../services/shopping-cart.service';

export const ShoppingCartIconLink = ({className = ''}) => {
  const [count, setCount] = useState(shoppingCartService.count)

  useEffect(() => {
    document.addEventListener(shoppingCartService.events.updateCount, updateCount, false);
    shoppingCartService.updateCount();

    return () => {
      document.removeEventListener(shoppingCartService.events.updateCount, updateCount, false);
    }
  }, [])

  function updateCount() {
    setCount(shoppingCartService.count);
  }

  function hasItem() {
    return count > 0;
  }

  return (
    <Link
      className={`${className} nav-icon-link`}
      to={shoppingCartLink.path}
      activeClassName="active"
    >
      <span className="uil uil-shopping-trolley"/>
      { hasItem() && <span className="count">{count}</span> }
    </Link>
  )
};

ShoppingCartIconLink.propTypes = {
  className: PropTypes.string
};

export default ShoppingCartIconLink;
