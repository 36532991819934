import React from 'react';
import { Link } from 'gatsby';
import ListAuthLinks from './ListAuthLinks';
import PropTypes from 'prop-types';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mainLinks, externalLink } from '../../../../../constants/nav-links.const';
import { hasActiveSubLink } from '../../../../../helpers/links.helper';

export const NavListSmall = ({navMobileActiveClass}) => {
  return (
    <div className="nav-mobile" id="navMobile">
      <div className={`wrap-nav-mobile pt-4 d-flex flex-column ${navMobileActiveClass}`}>
        <ul className="list-unstyled text-center mx-0 mb-7">
          {
            mainLinks.map((link, i) => (
              <li key={i} className="py-3 nav-item-mobile" style={{"animationDelay": `${((i + 1 ) * .2) }s`}}>
                <Link
                  className={`nav-link-mobile ${hasActiveSubLink(link.subLinks) ? 'active' : ''}`}
                  onClick={clearAllBodyScrollLocks}
                  to={link.path}
                  activeClassName="active"
                  partiallyActive={link.partiallyActive}
                >
                  {link.name}
                </Link>

                {
                  link.subLinks && (
                    <ul className="list-unstyled">
                      {
                        link.subLinks.map((subLink, key) => (
                          <li key={key} className="pt-2 pb-1 m-0">
                            <Link
                              className="nav-link-mobile nav-link-mobile-sm"
                              onClick={clearAllBodyScrollLocks}
                              to={subLink.path} activeClassName="active"
                              partiallyActive={true}
                            >
                              {subLink.name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }
              </li>
            ))
          }
          {
            externalLink.map((link, i) => (
              <li key={i} className="py-3 nav-item-mobile" style={{"animationDelay": `${((mainLinks.length + 2 ) * .2) }s`}}>
                <a className="nav-link-mobile"  href={link.path} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))
          }
        </ul>
        <div
          className="auth-block d-flex justify-content-center mt-auto mb-10"
          style={{"animationDelay": `${((mainLinks.length + 3 ) * .2) }s`}}
        >
          <ListAuthLinks/>
        </div>
      </div>
    </div>
  );
};


NavListSmall.propTypes = {
  navMobileActiveClass: PropTypes.string,
};

export default NavListSmall;
