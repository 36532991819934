import React, {useEffect, useState} from 'react';
import {authLinks} from '../../../../../constants/nav-links.const';
import hasWindow from '../../../../../constants/has-window.const';
import shoppingCartService from '../../../../../services/shopping-cart.service';

export const ListAuthLinks = () => {
  const trialPrefix = 'trial';
  const [isTrialPage, setIsTrialPage] = useState(false);
  const [userCartToken, setUserCartToken] = useState(shoppingCartService.getCartToken());

  const handleCartTokenChange = () => {
    setUserCartToken(shoppingCartService.getCartToken());
  };

  useEffect(() => {
    if (shoppingCartService.updateTokenEvent) {
      document.addEventListener(shoppingCartService.events.updateToken, handleCartTokenChange, false);
    }

    return () => {
      if (shoppingCartService.updateTokenEvent) {
        document.removeEventListener(shoppingCartService.events.updateToken, handleCartTokenChange, false);
      }
    };
  }, []);

  if (hasWindow) {
    useEffect(() => {
      const path = location.pathname;
      setIsTrialPage(path.includes(trialPrefix));
    }, [location.href])
  }

  return !isTrialPage && (
    <ul className="d-flex list-unstyled m-0">
      {
        authLinks.map((link, i) => (
          <li className="nav-item mx-2 ml-xl-3 mr-lg-0" key={i}>
            <a
              className="btn-tg btn-tg-secondary-inverse btn-nav-link"
              href={link.path + (userCartToken ? `?userCart=${userCartToken}` : '')}
              target="_blank"
              rel="noopener noreferrer"
            >{link.name}</a>
          </li>
        ))
      }
    </ul>
  );
}

export default ListAuthLinks;
