import React from 'react';
import { Link } from 'gatsby';
import TGLogo from '../../../../img/oldlogo.png';
import './footer.scss';
import PreviewCompatibleImage from "../../../Common/PreviewCompatibleImage";
import PropTypes from "prop-types";

const footerLinks = [
  {
    name:'Terms of Use',
    path: `/terms`
  },
  {
    name:'Privacy Policy',
    path: `/privacy`
  },
];

const Footer = ({ socialLinks }) => {
  const listFooterLinks = (
    <ul className="m-0 list-unstyled d-flex">
      { footerLinks.map((link, i) => (
          <li key={i} className="ml-3">
            <Link
                className="link-dark text-uppercase small"
                to={link.path}
                title={link.name}
            >{link.name}</Link>
          </li>
      ))}
    </ul>
  );

  const listSocialLinks = (
    <ul className="m-0 list-unstyled d-flex">
      {
        Object.keys(socialLinks).map((link) => (
          <li key={link} className="ml-3">
            <a className="link-dark link-social" href={socialLinks[link].url} target="_blank" rel="noopener" title={link}>
              {socialLinks[link] && socialLinks[link].icon && (<PreviewCompatibleImage
                imageInfo={{
                  image: socialLinks[link].icon,
                  alt: link,
                  className: "social-icon"
                }}
              />)}
            </a>
          </li>
        ))
      }
    </ul>
  );

  return (
    <footer className="footer py-3">
      <nav>
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center flex-column flex-md-row">
              <div className="brand-logo">
                <Link to="/" title="Totally guitars">
                  <img src={TGLogo} alt="Totally Guitars" />
                </Link>
              </div>
              <div className="my-3 my-md-0 ml-md-auto">
                { listFooterLinks }
              </div>
              <div >
                { listSocialLinks }
              </div>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  )
};

Footer.propTypes = {
  socialLinks: PropTypes.object,
};

export default Footer;
