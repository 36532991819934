import axios from 'axios';
import * as qs from 'qs';
import * as HttpStatus from 'http-status-codes';
import moment from 'moment';
import hasWindow from '../constants/has-window.const';

const axiosService = axios;

axiosService.defaults.paramsSerializer = function(params) {
  return qs.stringify(params, { indices: false });
}

axiosService.interceptors.request.use(
  (config) => {
    if (isIE()) {
      config.params = { ...config.params, ...{ timeStamp: moment().valueOf() } };
    }

    return config;
  }
);

export function isInternalServerError(error) {
  return error && error.response && error.response.status && error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR;
}

export default axiosService;

function isIE() {
  if (!hasWindow) {
    return false;
  }
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}
