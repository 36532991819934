/*global Sentry*/
import React from "react";
import {Link} from "gatsby";
import './errorBoundary.scss'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({error, eventId});
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <React.Fragment>
          <div className="errorBoundary">
            <h1>Sorry, something went wrong!</h1>
            <p className="mb-4">The link you followed may be broken, or the page may have been removed.</p>
            <button className="mb-4 button px-3 py-2 wood-white-background border-0" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
            <Link to="/">
              Go back to homepage
            </Link>
          </div>
        </React.Fragment>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
