import { isInternalServerError } from '../services/axios.service';
import NotificationService from '../services/notification.service';
import { isNotEmptyArray } from './arrays.helper';

const defaultMessage = 'Oops! Something went wrong.';
export function handleBadRequestError(error, onFieldErrorCallback = () => {}) {

  if (isInternalServerError(error) || !error) {
    NotificationService.error(defaultMessage);
    throw error;
  }

  if (error.response && error.response.data) {
    if (isNotEmptyArray(error.response.data.message)) {
      const messages = error.response.data.message;
      messages.forEach(field => {
        onFieldErrorCallback(field);
      })
    }
    else {
      const message = error.response.data.message || error.response.statusText || defaultMessage;
      NotificationService.warning(message);
    }
  } else {
    NotificationService.warning(defaultMessage);
  }
}

export function handleInternalServerError(error) {
  if (isInternalServerError(error) || !error) {
    NotificationService.error(defaultMessage);
    throw error;
  }
}

export function handleError(error, statusList) {
  if (
    Array.isArray(statusList)
    && error.response
    && statusList.includes(error.response.status)
    && error.response.data
    && typeof error.response.data.message === 'string'
  ) {
    NotificationService.warning(error.response.data.message);
  } else {
    throw error;
  }
}
