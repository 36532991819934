import React from 'react';
import { Link } from 'gatsby';
import ListAuthLinks from './ListAuthLinks';
import { mainLinks, externalLink, shoppingCartLink, giftsLink } from '../../../../../constants/nav-links.const';
import { hasActiveSubLink } from '../../../../../helpers/links.helper';
import ShoppingCartIconLink from './ShoppingCartIconLink';

export const NavListLarge = () => (
  <div
    id="navMenu"
    className="navbar-menu d-flex flex-grow-1"
  >
    <ul className="mb-0 mr-auto ml-auto d-flex list-unstyled align-items-center">
      {
        mainLinks.map((link, i) => (
          <li className="nav-item" key={i}>
            <Link
              className={`nav-link ${hasActiveSubLink(link.subLinks) ? 'active' : ''}`}
              to={link.path} activeClassName="active"
              partiallyActive={link.partiallyActive}
            >
              {link.name}
            </Link>
          </li>
        ))
      }
      {
        externalLink.map((link, i) => (
          <li className="nav-item" key={i}>
            <a className="nav-link" href={link.path} target="_blank" rel="noopener noreferrer">
              {link.name}
            </a>
          </li>
        ))
      }
    </ul>
    <div className={`align-self-center mr-2 mr-lg-1 ml-auto`}>
      <Link className={`pl-2 pl-md-0 pr-lg-0 pr-xl-2 nav-icon-link`} to={giftsLink.path} activeClassName="active">
        <span className="uil uil-gift"/>
      </Link>
      <ShoppingCartIconLink className="pl-0" />
    </div>
    <ListAuthLinks/>
  </div>
);

export default NavListLarge;
